// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bT2fU{max-width:375px;text-align:center}.bpbcs{color:var(--wpp-primary-color-500)}.tZdP5{display:flex;flex-direction:column;row-gap:10px;margin-top:10px;margin-bottom:10px;overflow-y:auto}.elT_E::part(checkbox){display:none}.T8w28{display:flex}.B7XWu{height:32px}.A24YH{color:var(--wpp-grey-color-800)}.L6BxT{padding-top:10px;border-top:1px solid var(--wpp-grey-color-300)}.wRn2_{width:108px;min-width:108px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/members/components/inviteMembersModal/memberRecommendations/steps/recommendations/Recommendations.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,iBAAA,CAGF,OACE,kCAAA,CAGF,OACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,eAAA,CACA,kBAAA,CACA,eAAA,CAIA,uBACE,YAAA,CAIJ,OACE,YAAA,CAGF,OACE,WAAA,CAGF,OACE,+BAAA,CAGF,OACE,gBAAA,CACA,8CAAA,CAGF,OACE,WAAA,CACA,eAAA","sourcesContent":[".searchingText {\n  max-width: 375px;\n  text-align: center;\n}\n\n.selectedMembersCount {\n  color: var(--wpp-primary-color-500);\n}\n\n.usersGroup {\n  display: flex;\n  flex-direction: column;\n  row-gap: 10px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  overflow-y: auto;\n}\n\n.userCard {\n  &::part(checkbox) {\n    display: none;\n  }\n}\n\n.externalLink {\n  display: flex;\n}\n\n.userSourceImage {\n  height: 32px;\n}\n\n.jobDepartment {\n  color: var(--wpp-grey-color-800);\n}\n\n.recommendedFor {\n  padding-top: 10px;\n  border-top: 1px solid var(--wpp-grey-color-300);\n}\n\n.labelRecommendedFor {\n  width: 108px;\n  min-width: 108px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchingText": `bT2fU`,
	"selectedMembersCount": `bpbcs`,
	"usersGroup": `tZdP5`,
	"userCard": `elT_E`,
	"externalLink": `T8w28`,
	"userSourceImage": `B7XWu`,
	"jobDepartment": `A24YH`,
	"recommendedFor": `L6BxT`,
	"labelRecommendedFor": `wRn2_`
};
export default ___CSS_LOADER_EXPORT___;
