import {
  WppActionButton,
  WppCard,
  WppCheckbox,
  WppDivider,
  WppIconApp,
  WppIconPair,
  WppIconGear,
  WppIconMore,
  WppIconPlus,
  WppIconRemoveCircle,
  WppListItem,
  WppMenuContext,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { Identifier } from 'dnd-core'
import { forwardRef, PropsWithChildren, useMemo } from 'react'
import { ConnectDragSource } from 'react-dnd'
import { useTranslation } from 'react-i18next'

import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useAssignMember } from 'hooks/useAssignMember'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { showEditPhaseModal } from 'pages/project/components/canvas/components/editPhaseModal/EditPhaseModal'
import { useUiPartEnabled } from 'pages/project/components/canvas/components/item/utils'
import { Calendar } from 'pages/project/components/canvas/components/phase/Calendar'
import styles from 'pages/project/components/canvas/components/phase/Phase.module.scss'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/phase/ResponsiblePerson'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import { useDeleteLinearPhase } from 'pages/project/components/canvas/hooks/useDeleteLinearPhase'
import { useHighligtPreferences } from 'pages/project/components/canvas/hooks/useHighlightPreferences'
import { useUpdatePhase } from 'pages/project/components/canvas/hooks/useUpdatePhase'
import { Column } from 'pages/project/components/canvas/utils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { isEqualEmails } from 'utils/common'

interface Props {
  column: Column
  index: number
  isEditable?: boolean
  toggleAppPickerModal?: (b: string) => void
  toggleActivityPickerModal?: (b: string) => void
  variant?: 'primary' | 'secondary'
  preview?: boolean
  dragRef?: ConnectDragSource
  dragHandlerId?: MayBeNull<Identifier>
  isDragging?: boolean
  isInactive?: boolean
  isDraggingDisabled?: boolean
}

export const Phase = forwardRef<HTMLWppCardElement, PropsWithChildren<Props>>(
  (
    {
      column,
      children,
      index,
      toggleAppPickerModal,
      toggleActivityPickerModal,
      isEditable,
      variant,
      preview,
      dragRef,
      dragHandlerId,
      isDragging,
      isInactive,
      isDraggingDisabled,
    },
    ref,
  ) => {
    const {
      osContext: { userDetails },
    } = useOs()
    const { project, highlightPreferences } = useProject()
    const { deletePhase } = useDeleteLinearPhase({ projectId: project.id })

    const { t } = useTranslation()
    const { hasRole } = useHasProjectRole()
    const { isPermitted } = useIsPermitted()
    const isOwnerOrGlobalManage =
      hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

    const { startDate, endDate, assignUser, description, id, name } = column
    const assignMember = useAssignMember(assignUser)
    const { updatePhase } = useUpdatePhase({ phaseId: id, projectId: project.id })

    const isMeAssignToThisPhase = useMemo(
      () => isEqualEmails(userDetails.email, assignMember?.email),
      [userDetails, assignMember],
    )

    const showAction = isEditable && (isOwnerOrGlobalManage || isMeAssignToThisPhase)
    const isDisabled = (isEditable && !showAction) || isInactive
    const isUIPartEnabled = useUiPartEnabled(isEditable)

    const toggleAssignee = async (newAssignee: ResponsibleUser) => {
      const isDeselecting = assignMember?.id === newAssignee.id
      await updatePhase({ assignUser: isDeselecting ? null : newAssignee, name: column.name })
    }

    const isHighlightedPhase = useMemo(() => highlightPreferences?.phaseId === id, [highlightPreferences, id])

    const { createHighlightPreference, patchHighlightPreference, deleteHighlightPreference } = useHighligtPreferences({
      projectId: project.id,
    })

    const handleHighlightPhase = () => {
      if (isHighlightedPhase && highlightPreferences) {
        if (highlightPreferences.applications.length > 0) {
          patchHighlightPreference({ phaseId: null, applications: [] })
        } else {
          deleteHighlightPreference()
        }
        return
      }
      if (highlightPreferences) {
        patchHighlightPreference({ phaseId: id })
      } else {
        createHighlightPreference({ phaseId: id, name: name, applications: [] })
      }
    }

    const handleDelete = async () => {
      await deletePhase(id, column.name)
    }

    return (
      <WppCard
        ref={ref}
        className={clsx(styles.phase, {
          [styles.columnCanvas]: isEditable,
          [styles.columnOverview]: !isEditable,
          [styles.columnPhase]: preview,
          [styles.columnDragging]: isDragging,
          [styles.phaseHighlighted]: isHighlightedPhase,
        })}
        variant={variant}
        data-testid={`project-phase-card-${column.id}`}
        data-handler-id={dragHandlerId}
        size="s"
      >
        <Flex
          direction="column"
          gap={preview ? 4 : 8}
          ref={dragRef}
          className={clsx({
            [styles.headerWrapperWorkflow]: preview,
            [styles.headerWrapper]: !preview,
            [styles.headerDrag]: !!dragRef && !isDisabled && !isInactive && !isDraggingDisabled,
          })}
          data-testid="project-phase-header"
        >
          <Flex justify="between" gap={8} align="center">
            <Truncate lines={2} slot="header" type="m-strong" data-testid="phase-name">
              {column.name}
            </Truncate>

            <Flex align="center" className={styles.alignSelf}>
              {showAction && (
                <WppMenuContext key={index} slot="actions" dropdownConfig={{ appendTo: () => document.body }}>
                  <WppActionButton slot="trigger-element" variant="secondary">
                    <WppIconMore slot="icon-start" direction="horizontal" />
                  </WppActionButton>
                  <Flex direction="column" gap={4}>
                    <WppListItem checked={isHighlightedPhase} onWppChangeListItem={handleHighlightPhase}>
                      <Flex slot="label" align="center">
                        <WppCheckbox
                          checked={isHighlightedPhase}
                          required
                          labelConfig={{ text: t('project.phase.mark_as_active') }}
                          className={clsx(isHighlightedPhase && styles.highlightedCheckboxLabel)}
                        />
                      </Flex>
                    </WppListItem>
                    <WppListItem
                      onWppChangeListItem={() => showEditPhaseModal({ phase: column, isDisabled: isInactive })}
                      data-testid="context-settings"
                    >
                      <WppIconGear slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('project.phase.settings')}
                      </WppTypography>
                    </WppListItem>
                    <WppListItem
                      style={{ display: isInactive ? 'none' : 'block' }}
                      onWppChangeListItem={() =>
                        showDeleteModal({
                          title: t('project.canvas.delete_phase_title'),
                          subTitle: t('project.canvas.delete_phase'),
                          deleteText: t('project.canvas.delete')!,
                          onDelete: handleDelete,
                        })
                      }
                      data-testid="context-remove"
                    >
                      <WppIconRemoveCircle slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('common.btn_delete')}
                      </WppTypography>
                    </WppListItem>
                  </Flex>
                </WppMenuContext>
              )}
            </Flex>

            {preview && isUIPartEnabled(ProjectPartKey.ResponsiblePersons) && (
              <Flex data-testid="phase-assignee">
                <ResponsiblePerson assignMember={assignMember} size="xs" hideIcon />
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap={preview ? 4 : 8}>
            {!!description && isUIPartEnabled(ProjectPartKey.Descriptions) && (
              <>
                {preview ? (
                  <WppTypography type="xs-body" className={styles.greyColor800} data-testid="phase-description">
                    {description}
                  </WppTypography>
                ) : (
                  <WppTypography type="xs-body" data-testid="phase-description" className={styles.grey}>
                    {description}
                  </WppTypography>
                )}
              </>
            )}

            {preview ? (
              <div data-testid="phase-dates">
                {startDate && endDate && isUIPartEnabled(ProjectPartKey.Dates) && (
                  <Calendar startDate={startDate} endDate={endDate} small={preview} hideIcon={preview} />
                )}
              </div>
            ) : (
              <div className={clsx({ [styles.disabledPhase]: isDisabled })}>
                <Flex
                  gap={!showAction || isInactive ? 10 : 0}
                  className={clsx({ [styles.headerIcons]: !preview })}
                  align="center"
                >
                  {showAction && !isInactive ? (
                    <Flex gap={4} align="center">
                      <SelectPersonInline selectedId={assignMember?.id} onChange={toggleAssignee}>
                        <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="phase-item-assignee" />
                      </SelectPersonInline>

                      <SelectDateInline
                        startDate={startDate}
                        endDate={endDate}
                        onChange={dates => updatePhase({ dates, name: column.name })}
                      />
                    </Flex>
                  ) : (
                    <Flex gap={10} align="center">
                      <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="phase-item-assignee" />
                      <Calendar startDate={startDate} endDate={endDate} small={preview} hideIcon={preview} />
                    </Flex>
                  )}

                  {showAction && (
                    <div className={clsx(styles.addMenuWrapper, { [styles.isDisabled]: isInactive })}>
                      <WppMenuContext data-testid="context-add">
                        <WppActionButton slot="trigger-element" disabled={isInactive}>
                          <WppIconPlus slot="icon-start" />
                          {t('project.canvas.btn_add_item')}
                        </WppActionButton>
                        <Flex direction="column" gap={4}>
                          <WppListItem
                            onWppChangeListItem={() => toggleAppPickerModal?.(column.id)}
                            data-testid="context-add-app"
                          >
                            <WppIconApp slot="left" />
                            <p slot="label">{t('project.canvas.application')}</p>
                          </WppListItem>
                          <WppListItem
                            onWppChangeListItem={() => toggleActivityPickerModal?.(column.id)}
                            data-testid="context-add-activity"
                          >
                            <WppIconPair slot="left" />
                            <p slot="label">{t('project.canvas.activity')}</p>
                          </WppListItem>
                        </Flex>
                      </WppMenuContext>
                    </div>
                  )}
                </Flex>
              </div>
            )}
          </Flex>
        </Flex>
        <WppDivider
          className={clsx({
            [styles.previewDivider]: preview,
            [styles.headerWrapperWorkflow]: preview,
            [styles.divider]: !preview,
          })}
        />
        <div />
        {children}
      </WppCard>
    )
  },
)
