import { WppCard, WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import styles from 'pages/project/components/canvas/components/appPikerModal/nativeApps/NativeApps.module.scss'
import { SelectVersion } from 'pages/project/components/canvas/components/appPikerModal/versions/SelectVersion'
import { NativeAppDTO, NativeAppType, NativeAppVersionDTO } from 'types/products/nativeApp'

interface Props {
  app: NativeAppDTO
  selectedVersion: NativeAppVersionDTO
  isMiroEnabled: boolean
  selectVersion: (version: NativeAppVersionDTO) => void
}

export const NativeAppCard = ({ app, selectVersion, selectedVersion, isMiroEnabled }: Props) => {
  const { t } = useTranslation()

  // only Miro apps can be disabled here (in case when user has no valid Miro token)
  const isDisabled = app.type === NativeAppType.MIRO_BOARD ? !isMiroEnabled : false

  const capitalizedWords = useMemo(() => {
    return app.categories.map((word, index) => (
      <Fragment key={word}>
        {word.charAt(0) + word.slice(1).toLowerCase()}
        {index !== app.categories.length - 1 ? ', ' : ''}
      </Fragment>
    ))
  }, [app.categories])

  return (
    <WppCard value={app.id} className={styles.card} disabled={isDisabled} data-testid="application-card">
      <WppTooltip
        className={styles.tooltip}
        header={t('modals.add_from_marketplace.miro_tooltip_disabled.header')!}
        text={t('modals.add_from_marketplace.miro_tooltip_disabled.text')!}
        config={{
          trigger: isDisabled ? 'mouseenter' : 'manual',
          maxWidth: 250,
        }}
      >
        <Flex direction="row" gap={10} align="start">
          <Flex inline direction="column" justify="center" align="center">
            <ApplicationLogo logo={app?.logoUrl} size={40} />
          </Flex>

          <Flex direction="column" className={styles.cardBody} gap={12}>
            <Flex direction="column">
              <Truncate lines={1} type="m-strong" className={styles.colGrey1000} data-testid="product-name">
                {app.name}
              </Truncate>

              <WppTypography type="xs-body" className={styles.colGrey800}>
                {app.ownerName}
              </WppTypography>
            </Flex>

            {!!app.shortDescription && (
              <Truncate type="s-body" lines={1} className={styles.colGrey800}>
                {app.shortDescription}
              </Truncate>
            )}

            <Flex>
              {!!app?.categories?.length && (
                <WppTypography className={styles.cardPill} type="xs-body">
                  {capitalizedWords}
                </WppTypography>
              )}
            </Flex>
            {(app.versions?.length ?? 0) > 1 && (
              <SelectVersion options={app.versions!} selectedVersion={selectedVersion} selectVersion={selectVersion} />
            )}
          </Flex>
        </Flex>
      </WppTooltip>
    </WppCard>
  )
}
