import { projectApi } from 'api'
import { ProjectRole } from 'types/permissions/permissions'
import { ExternalMember, ProjectMember, ProjectMemberType } from 'types/projects/projectMembers'

interface InviteProjectMember {
  projectId: string
  role: ProjectRole
  internalMembers: { email: string }[]
  externalMembers: ExternalMember[]
  accessType?: ProjectMemberType
}

export const inviteProjectMemberApi = (body: InviteProjectMember) => projectApi.post<ProjectMember[]>('/members', body)
