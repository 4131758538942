import {
  WppActionButton,
  WppIconEdit,
  WppIconGear,
  WppIconLink,
  WppIconPitch,
  WppListItem,
  WppTab,
  WppTabs,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, matchPath, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import jiraSrc from 'assets/jira.svg'
import wrikeSrc from 'assets/wrike.svg'
import { Flex } from 'components/common/flex/Flex'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { BackToProjectsButton } from 'pages/components/backToProjectsButton/BackToProjectsButton'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import { ProjectManageMenu } from 'pages/dashboard/components/projectManageMenu/ProjectManageMenu'
import { Banner } from 'pages/project/components/banner/Banner'
import { CopyType, useCopyUrlToClipboard } from 'pages/project/hooks/useCoppyUrlToClipboard'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { useOpenAppPreviewModal } from 'pages/project/hooks/useOpenAppPreviewModal'
import { useOpenFilePreviewModal } from 'pages/project/hooks/useOpenFilesModal'
import { useOpenTaskPreviewModal } from 'pages/project/hooks/useOpenTaskPreviewModal'
import styles from 'pages/project/Project.module.scss'
import { getTimeFromCreation, PROJECT_SETUP_TIME } from 'pages/project/utils/projectState'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProcessType, ProjectStatus } from 'types/projects/projects'
import { findNavigationTreeParentNodeAzId } from 'utils/navigationTree'
import { projectTabs } from 'utils/project'
import { routesManager } from 'utils/routesManager'

export const Project = () => {
  const {
    osContext: {
      navigationTree: { mapping },
    },
  } = useOs()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    osContext: { tenant },
  } = useOs()

  const { project, isInactive, wrike } = useProject()
  const { name, id, status, contextWorkspace, createdAt } = project
  const workspaceData = findNavigationTreeParentNodeAzId(contextWorkspace, mapping)
  const isWorkspaceValid = !!contextWorkspace ? !!workspaceData.length : true
  const isFossilTenant = tenant.id === 'de211404-5939-44d4-a59f-774198ea494b'

  const isProjectSetupPending = getTimeFromCreation(createdAt) < PROJECT_SETUP_TIME
  const bannerStatus = !isWorkspaceValid ? 'WORKSPACE_ERROR' : isProjectSetupPending ? 'PROJECT_SETUP_PENDING' : status

  const { isPermitted } = useIsPermitted()
  const { hasRole, isResponsible } = useHasProjectRole()

  // @TODO: find the way how it can be handled
  useOpenFilePreviewModal(project)
  useOpenTaskPreviewModal()
  useOpenAppPreviewModal()

  const { copyToClipboardAction } = useCopyUrlToClipboard(CopyType.PROJECT)

  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const isOwner = hasRole([ProjectRole.OWNER]) || isGlobalManage

  const tabs = isGlobalManage
    ? projectTabs
    : projectTabs.filter(({ roles, forResponsible }) => {
        if (forResponsible && isResponsible()) return true
        return !roles || hasRole(roles)
      })

  const currentTab = matchPath(`${routesManager.project.root.pattern}/:tab`, location.pathname)
  const isTabAllowed = tabs.some(({ id }) => id === currentTab?.params.tab)

  const handleEditWorkflow = useCallback(
    () => navigate(routesManager.project.canvas.getURL({ id: project.id })),
    [navigate, project.id],
  )
  const onDelete = useCallback(() => navigate(routesManager.projects.root.getURL()), [navigate])

  if (!currentTab) return <Navigate to={routesManager.project.workflow.getURL({ id })} replace />

  if (!isTabAllowed) return <Navigate to={routesManager.project.workflow.getURL({ id })} replace />

  return (
    <>
      <Banner status={bannerStatus} action={() => showProjectEditModal({ project: project })} />
      <Flex
        direction="column"
        className={clsx(styles.container, {
          [styles.containerWithBar]: bannerStatus !== ProjectStatus.ACTIVE,
        })}
      >
        <BackToProjectsButton />
        <Flex direction="column" gap={16} className={styles.headContainer}>
          <Flex justify="between">
            <WppTypography type="3xl-heading" data-testid="project-name">
              {name}
            </WppTypography>

            {isOwner && (
              <Flex justify="between">
                {project.processType === ProcessType.LINEAR && (
                  <WppActionButton
                    onClick={() => navigate(routesManager.project.demo.getURL({ id }))}
                    data-testid="project-demo"
                    variant="secondary"
                  >
                    <WppIconPitch slot="icon-start" />

                    {t('project.demo.title')}
                  </WppActionButton>
                )}
                <div>
                  <ProjectManageMenu project={project} onDelete={onDelete} manageStatus>
                    <WppListItem
                      data-testid="project-settings-btn"
                      onWppChangeListItem={() => showProjectEditModal({ project: project })}
                      disabled={isInactive}
                    >
                      <WppIconGear slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('project.page.project_settings')}
                      </WppTypography>
                    </WppListItem>

                    <WppListItem data-testid="copy-project-url" onWppChangeListItem={copyToClipboardAction}>
                      <WppIconLink slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('product.copy_project_link')}
                      </WppTypography>
                    </WppListItem>
                    {!isInactive && (
                      <WppListItem data-testid="edit-project-workflow" onWppChangeListItem={() => handleEditWorkflow()}>
                        <WppIconEdit slot="left" />
                        <WppTypography slot="label" type="s-body">
                          {t('project.demo.edit_workflow')}
                        </WppTypography>
                      </WppListItem>
                    )}
                  </ProjectManageMenu>
                </div>
              </Flex>
            )}
          </Flex>

          <Flex gap={24}>
            <WppTabs
              // we need to update the component if amount of tabs changed w/o the value change - WPPLONOP-18006
              key={tabs.length}
              value={currentTab?.params.tab || routesManager.project.overview.shortPattern}
              size="s"
              className={styles.nav}
            >
              {tabs.map(({ id, value }) => (
                <Link to={id} key={id} tabIndex={-1}>
                  <WppTab value={id} size="s">
                    {value}
                  </WppTab>
                </Link>
              ))}
              {wrike?.wrikeProjectUrl && (
                <Link to={wrike.wrikeProjectUrl} tabIndex={-1} target="_blank">
                  <WppTab value={currentTab.params.tab}>
                    {isFossilTenant ? (
                      <>
                        <img src={jiraSrc} className={styles.jiraIcon} alt="Jira logo" /> Jira
                      </>
                    ) : (
                      <>
                        <img src={wrikeSrc} className={styles.wrikeIcon} alt="Wrike logo" /> Wrike
                      </>
                    )}
                  </WppTab>
                </Link>
              )}
            </WppTabs>
          </Flex>
        </Flex>

        <Outlet />
      </Flex>
    </>
  )
}
