// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cdixo::part(message){display:inline-flex;gap:12px;align-items:center}.NNfeZ::part(body){background-color:var(--wpp-grey-color-300)}.NNfeZ::part(message){color:var(--wpp-grey-color-800)}.k0ln0::part(body){background-color:var(--wpp-success-color-400)}.k0ln0::part(message){color:var(--wpp-grey-color-000)}.jDTP5{--wpp-banner-content-wrapper-max-width: auto}.jDTP5::part(body){background-color:var(--wpp-highlight-color-400)}.jDTP5::part(message){color:var(--wpp-grey-color-1000)}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/banner/Banner.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,QAAA,CACA,kBAAA,CAIA,mBACE,0CAAA,CAGF,sBACE,+BAAA,CAKF,mBACE,6CAAA,CAGF,sBACE,+BAAA,CAIJ,OACE,4CAAA,CAEA,mBACE,+CAAA,CAGF,sBACE,gCAAA","sourcesContent":[".statusBanner::part(message) {\n  display: inline-flex;\n  gap: 12px;\n  align-items: center;\n}\n\n.archivedBanner {\n  &::part(body) {\n    background-color: var(--wpp-grey-color-300);\n  }\n\n  &::part(message) {\n    color: var(--wpp-grey-color-800);\n  }\n}\n\n.completedBanner {\n  &::part(body) {\n    background-color: var(--wpp-success-color-400);\n  }\n\n  &::part(message) {\n    color: var(--wpp-grey-color-000);\n  }\n}\n\n.warningBanner {\n  --wpp-banner-content-wrapper-max-width: auto;\n\n  &::part(body) {\n    background-color: var(--wpp-highlight-color-400);\n  }\n\n  &::part(message) {\n    color: var(--wpp-grey-color-1000);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusBanner": `Cdixo`,
	"archivedBanner": `NNfeZ`,
	"completedBanner": `k0ln0`,
	"warningBanner": `jDTP5`
};
export default ___CSS_LOADER_EXPORT___;
