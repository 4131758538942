// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vSqNu{padding:20px 0 40px}.LFkya{white-space:nowrap}.xLJqB{width:32px;height:32px;border-radius:50%}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/members/components/inviteMembersModal/InviteMembersModal.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CAGF,OACE,kBAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".stepper {\n  padding: 20px 0 40px;\n}\n\n.stepLabel {\n  white-space: nowrap;\n}\n\n.userAvatarImage {\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepper": `vSqNu`,
	"stepLabel": `LFkya`,
	"userAvatarImage": `xLJqB`
};
export default ___CSS_LOADER_EXPORT___;
