import {
  WppCard,
  WppIconCalendar3Days,
  WppIconFile,
  WppIconPeople,
  WppIconVideoClip,
  WppInlineMessage,
  WppLabel,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { HierarchyContainerNodeId } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormRadioGroup } from 'components/form/formRadioGroup/FormRadioGroup'
import { FormSelect, FormSelectOption } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { useHierarchy } from 'hooks/useHierarchy'
import styles from 'pages/components/projectModal/components/projectForm/ProjectForm.module.scss'
import { WorkspaceWarning } from 'pages/components/workspaceWarning/WorkspaceWarning'
import { SelectProcessType } from 'pages/project/components/canvas/components/selectProcessType/SelectProcessType'
import { ContextHierarchy, ProjectType } from 'types/projects/projects'
import { capitalizeFirstLetter } from 'utils/common'

const projectTypePreset = [
  {
    value: ProjectType.BLANK,
    icon: <WppIconFile color="var(--wpp-dataviz-color-cat-dark-1)" />,
    title: 'Blank',
  },
  {
    value: ProjectType.PITCH,
    icon: <WppIconVideoClip color="var(--wpp-dataviz-color-cat-dark-3)" />,
    title: 'Pitch',
  },
  {
    value: ProjectType.WORKSHOP,
    icon: <WppIconPeople color="var(--wpp-dataviz-color-cat-dark-5)" />,
    title: 'Workshop',
  },
  {
    value: ProjectType.CAMPAIGN,
    icon: <WppIconCalendar3Days color="var(--wpp-dataviz-color-cat-dark-7)" />,
    title: 'Campaign',
  },
]

const projectTypeOptions = projectTypePreset.map(({ value, icon, title }) => (
  <WppCard key={value} value={value}>
    <Flex direction="column" align="center">
      <Flex justify="center" align="center" gap={10} className={clsx(styles.cardIcon, styles[value])}>
        {icon}
      </Flex>
      <WppTypography type="s-strong">{title}</WppTypography>
    </Flex>
  </WppCard>
))

export const ProjectForm = ({
  showProcessType = true,
  contextHierarchy = [],
  showWarning = false,
  templateId,
}: {
  showProcessType?: boolean
  showWarning?: boolean
  templateId?: string
  contextHierarchy?: ContextHierarchy[]
}) => {
  const { t } = useTranslation()
  const { watch, setValue } = useFormContext()
  const {
    osContext: { navigationTree, tenant },
  } = useOs()
  const { hierarchyOrder } = useHierarchy()

  const { mapping } = navigationTree

  const mapNavigationToOption = useCallback(
    (children: string[]) => {
      const options = (children || [])
        .map(id => {
          const { name, type } = mapping[id] || {}

          const handleThisLvl = tenant.hierarchyLevels.some(level => level.type === type)

          if (!handleThisLvl) return null
          return { label: name, value: id }
        })
        .filter(option => option?.label) as FormSelectOption[]

      options.sort((a, b) => a.label?.localeCompare(b.label))

      return options
    },
    [mapping, tenant.hierarchyLevels],
  )

  const formWatch = watch(hierarchyOrder)

  return (
    <Flex direction="column" gap={69}>
      <Flex direction="column" gap={24}>
        <FormInput
          name="name"
          data-testid="modal-project-name"
          labelConfig={{ text: t('modals.create_project.field_name_label') }}
          placeholder={t('modals.create_project.field_name_placeholder')!}
          required
        />
        <Flex direction="column" gap={8}>
          <WppLabel
            data-testid="project-type-label"
            config={{ text: t('modals.create_project.field_type_label') }}
            typography="s-strong"
          />
          <FormRadioGroup
            data-testid="project-type"
            className={styles.radioGroup}
            withRadioOrCheckbox={false}
            name="type"
          >
            {projectTypeOptions}
          </FormRadioGroup>
        </Flex>

        {showProcessType && (
          <Flex direction="column" gap={8}>
            <WppLabel
              data-testid="project-process-type-label"
              config={{ text: t('modals.create_project.field_process_type_label') }}
              typography="s-strong"
            />
            <SelectProcessType
              name="processType"
              templateId={templateId}
              resetTemplateId={() => setValue('templateId', '')}
            />
          </Flex>
        )}

        <Flex direction="column" gap={24}>
          {showWarning && (
            <WppInlineMessage
              size="m"
              message={t('project.about.incorrect_workspace')}
              type="warning"
              showTooltipFrom={100}
            />
          )}
          <WorkspaceWarning contextHierarchy={contextHierarchy} />
          <div className={styles.grid}>
            {hierarchyOrder.map((hierarchy, index) => {
              const mappingLvl = index < 1 ? HierarchyContainerNodeId : formWatch[index - 1 || 0]
              const optionsList = mapNavigationToOption(mapping[mappingLvl]?.children)
              const isDisabled = index < 1 ? false : !mappingLvl || !optionsList.length

              return (
                <FormSelect
                  key={hierarchy}
                  name={hierarchy}
                  withSearch
                  data-testid={`${hierarchy}-select`}
                  options={optionsList}
                  labelConfig={{
                    text: t(`modals.create_project.field_${hierarchy}_label`, {
                      defaultValue: capitalizeFirstLetter(hierarchy),
                    }),
                  }}
                  placeholder={
                    t(`modals.create_project.field_${hierarchy}_placeholder`, {
                      defaultValue: capitalizeFirstLetter(hierarchy),
                    })!
                  }
                  required
                  toggleSelection
                  disabled={isDisabled}
                  onWppChange={() => hierarchyOrder.slice(index + 1).forEach(el => setValue(el, ''))}
                  className={
                    // @TODO: CL css var '--input-select-dropdown-max-height' doesn’t work, remove when they will fix it
                    clsx({ [styles.selectMaxHeight]: optionsList.length > 5 })
                  }
                />
              )
            })}
            {hierarchyOrder.length % 2 !== 0 && <div />}
            <FormDatepicker
              className={styles.datePicker}
              name="dueDate"
              placeholder={t('modals.create_project.field_due_date_placeholder')}
              labelConfig={{ text: t('modals.create_project.field_due_date_label') }}
              range
            />
            <div />
          </div>
        </Flex>
        <FormTextareaInput
          name="description"
          data-testid="project-description"
          labelConfig={{ text: t('modals.create_project.field_description_label') }}
          placeholder={t('modals.create_project.field_description_placeholder')!}
          warningThreshold={580}
          charactersLimit={600}
        />
      </Flex>
    </Flex>
  )
}
