// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h4zHQ{margin:8px 0}.FYLeG{margin-top:8px}.KiPEz::part(text){color:var(--wpp-primary-color-500)}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/item/ManageItemMenu.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,cAAA,CAIA,mBACE,kCAAA","sourcesContent":[".highlightTopDivider {\n  margin: 8px 0;\n}\n\n.highlightBottomDivider {\n  margin-top: 8px;\n}\n\n.highlightedCheckboxLabel {\n  &::part(text) {\n    color: var(--wpp-primary-color-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlightTopDivider": `h4zHQ`,
	"highlightBottomDivider": `FYLeG`,
	"highlightedCheckboxLabel": `KiPEz`
};
export default ___CSS_LOADER_EXPORT___;
