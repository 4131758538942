import { pitchRNDApi } from 'api'
import { MayBeNull } from 'types/common/utils'
import { HighlightApplication } from 'types/projects/highlightPreferences'

export interface PatchHighlightPreference {
  projectId: string
  phaseId?: MayBeNull<string>
  name?: string
  applications?: HighlightApplication[]
}

export const patchHighlightPreference = ({ projectId, ...body }: PatchHighlightPreference) =>
  pitchRNDApi.patch(`/projects/${projectId}/highlighted_preferences`, body)
