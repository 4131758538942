import {
  WppBanner,
  WppIconAvailableCheckmark,
  WppIconArchive,
  WppIconWarning,
  WppActionButton,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'pages/project/components/banner/Banner.module.scss'
import { ProjectStatus } from 'types/projects/projects'

interface StyleConfig {
  icon: ReactNode
  title: string
}

type BannerState = ProjectStatus | 'WORKSPACE_ERROR' | 'PROJECT_SETUP_PENDING'

const bannerStyles: Record<Exclude<BannerState, ProjectStatus.ACTIVE>, StyleConfig> = {
  [ProjectStatus.COMPLETED]: {
    icon: <WppIconAvailableCheckmark color="var(--wpp-grey-color-000)" />,
    title: 'project.page.project_completed',
  },
  [ProjectStatus.ARCHIVED]: {
    icon: <WppIconArchive color="var(--wpp-grey-color-600)" />,
    title: 'project.page.project_archived',
  },
  WORKSPACE_ERROR: {
    icon: <WppIconWarning color="var(--wpp-grey-color-1000)" />,
    title: 'project.page.project_workspace',
  },
  PROJECT_SETUP_PENDING: {
    icon: <WppIconWarning color="var(--wpp-grey-color-1000)" />,
    title: 'project.page.project_setup_pending',
  },
}

export const Banner = ({ status, action }: { status: BannerState; action: () => void }) => {
  const { t } = useTranslation()
  const isInactive = status !== ProjectStatus.ACTIVE

  return (
    <WppBanner
      show={isInactive}
      className={clsx(styles.statusBanner, {
        [styles.archivedBanner]: status === ProjectStatus.ARCHIVED,
        [styles.completedBanner]: status === ProjectStatus.COMPLETED,
        [styles.warningBanner]: status === 'WORKSPACE_ERROR' || status === 'PROJECT_SETUP_PENDING',
      })}
      data-testid="project-status-banner"
    >
      {isInactive && bannerStyles[status].icon}
      {isInactive && t(bannerStyles[status].title)}

      {status === 'WORKSPACE_ERROR' && (
        <WppActionButton onClick={action} variant="secondary" slot="actions">
          Update project settings
        </WppActionButton>
      )}
    </WppBanner>
  )
}
