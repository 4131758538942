// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{background-color:var(--wpp-grey-color-100)}.CnyIf{height:calc(100vh - var(--wpp-os-header-height));overflow:hidden}:root{--wpp-tab-width: 115px}.gf19I{height:calc(100vh - var(--wpp-os-header-height));padding:16px 24px 0;width:100%;max-width:1920px;margin:0 auto}.D2_6C{height:calc(100vh - var(--wpp-os-header-height) - 48px)}.U_SyF{height:100%}.ouYAy{width:100%;max-width:1920px;margin:0 auto;margin-bottom:16px}.Mc37F{width:24px}.OYMeC{width:18px;margin-right:6px}.UL4eR{width:100%}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss","webpack://./src/pages/project/Project.module.scss"],"names":[],"mappings":"AAAA,MACE,0CAAA,CAGF,OACE,gDAAA,CACA,eAAA,CCJF,MACE,sBAAA,CAGF,OACE,gDAAA,CACA,mBAAA,CDEA,UAAA,CACA,gBAAA,CACA,aAAA,CCCF,OAEE,uDAAA,CAGF,OACE,WAAA,CAGF,ODZE,UAAA,CACA,gBAAA,CACA,aAAA,CCaA,kBAAA,CAGF,OACE,UAAA,CAGF,OACE,UAAA,CACA,gBAAA,CAGF,OACE,UAAA","sourcesContent":[":root {\n  background-color: var(--wpp-grey-color-100);\n}\n\n.heightContainer {\n  height: calc(100vh - var(--wpp-os-header-height));\n  overflow: hidden;\n}\n\n@mixin containerWidthBoundaries {\n  width: 100%;\n  max-width: 1920px;\n  margin: 0 auto;\n}\n","@use 'src/app/App.module' as strictContainer;\n\n:root {\n  --wpp-tab-width: 115px;\n}\n\n.container {\n  height: calc(100vh - var(--wpp-os-header-height));\n  padding: 16px 24px 0;\n\n  @include strictContainer.containerWidthBoundaries;\n}\n\n.containerWithBar {\n  // bar height 48\n  height: calc(100vh - var(--wpp-os-header-height) - 48px);\n}\n\n.spinner {\n  height: 100%;\n}\n\n.headContainer {\n  @include strictContainer.containerWidthBoundaries;\n\n  margin-bottom: 16px;\n}\n\n.wrikeIcon {\n  width: 24px;\n}\n\n.jiraIcon {\n  width: 18px;\n  margin-right: 6px;\n}\n\n.nav {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heightContainer": `CnyIf`,
	"container": `gf19I`,
	"containerWithBar": `D2_6C`,
	"spinner": `U_SyF`,
	"headContainer": `ouYAy`,
	"wrikeIcon": `Mc37F`,
	"jiraIcon": `OYMeC`,
	"nav": `UL4eR`
};
export default ___CSS_LOADER_EXPORT___;
