import { HierarchyLevelType } from '@wpp-open/core/types/tenant'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

export const useHierarchy = () => {
  const {
    osContext: { tenant },
  } = useOs()

  const hierarchyOrder = useMemo(
    () =>
      tenant.hierarchyLevels
        .filter(({ type }) => type !== HierarchyLevelType.Tenant)
        .map(({ type }) => type.toLowerCase()),
    [tenant],
  )

  return { hierarchyOrder }
}
