import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useCreateHighlightPreference } from 'api/canvas/mutation/useCreateHighlightPreference'
import { useDeleteHighlightPreference } from 'api/canvas/mutation/useDeleteHighlightPreference'
import { usePatchHighlightPreference } from 'api/canvas/mutation/usePatchHighlightPreference'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { MayBeNull } from 'types/common/utils'
import { HighlightApplication } from 'types/projects/highlightPreferences'

interface Props {
  projectId: string
}

export const useHighligtPreferences = ({ projectId }: Props) => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()
  const { t } = useTranslation()

  const { mutateAsync: handleCreateHighlightPreference } = useCreateHighlightPreference()
  const { mutateAsync: handleDeleteHighlightPreference } = useDeleteHighlightPreference()
  const { mutateAsync: handlePatchHighlightPreference } = usePatchHighlightPreference()

  const createHighlightPreference = async ({
    phaseId,
    name,
    applications,
  }: {
    phaseId?: MayBeNull<string>
    name: string
    applications: HighlightApplication[]
  }) => {
    try {
      const body = {
        id: projectId,
        name,
        phaseId,
        applications,
      }

      await handleCreateHighlightPreference(body)
      queryClient.invalidateQueries([ApiQueryKeys.RND_HIGHLIGHT_PREFERENCES, { projectId }])
    } catch (e) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_highlight'),
      })
      console.error(e)
    }
  }

  const deleteHighlightPreference = async () => {
    try {
      await handleDeleteHighlightPreference({ projectId })
      await queryClient.resetQueries([ApiQueryKeys.RND_HIGHLIGHT_PREFERENCES, { projectId }])
      queryClient.removeQueries([ApiQueryKeys.RND_HIGHLIGHT_PREFERENCES, { projectId }])
    } catch (e) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_remove_highlight'),
      })
      console.error(e)
    }
  }

  const patchHighlightPreference = async ({
    phaseId,
    name,
    applications,
  }: {
    phaseId?: MayBeNull<string>
    name?: string
    applications?: HighlightApplication[]
  }) => {
    try {
      const body = {
        name,
        phaseId,
        applications,
      }

      await handlePatchHighlightPreference({ projectId, ...body })
      queryClient.invalidateQueries([ApiQueryKeys.RND_HIGHLIGHT_PREFERENCES, { projectId }])
    } catch (e) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_highlight_operation'),
      })
      console.error(e)
    }
  }

  return {
    createHighlightPreference,
    deleteHighlightPreference,
    patchHighlightPreference,
  }
}
