import styles from 'components/svg/common.module.scss'

export const DataArrowSvg = (props: JSX.IntrinsicElements['svg']) => (
  <svg {...props} width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM21.3536 4.35355C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464466C17.9763 0.269204 17.6597 0.269204 17.4645 0.464466C17.2692 0.659728 17.2692 0.976311 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53553C17.6597 7.7308 17.9763 7.7308 18.1716 7.53553L21.3536 4.35355ZM1 4.5H2.90476V3.5H1V4.5ZM5.7619 4.5H9.57143V3.5H5.7619V4.5ZM12.4286 4.5H16.2381V3.5H12.4286V4.5ZM19.0952 4.5H21V3.5H19.0952V4.5Z"
      className={styles.fillDataGrey500}
    />
  </svg>
)
