import { WppTag, WppTypography, WppDivider } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { useAssignMember } from 'hooks/useAssignMember'
import { t } from 'i18next'
import { NotOnboardedInfo } from 'pages/project/components/canvas/components/item/NotOnboardedInfo'
import { Calendar } from 'pages/project/components/canvas/components/phase/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/phase/ResponsiblePerson'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  application: ApplicationItem
}

export const AppDetailsModalContent = ({ application }: Props) => {
  const assignMember = useAssignMember(application?.assignUser)

  const selectedVersion = useMemo(
    () => application.externalAppVersions?.find(version => version.id === application.externalAppVersionId),
    [application],
  )

  return (
    <Flex direction="column" gap={12}>
      <NotOnboardedInfo application={application} />
      {application.description && (
        <WppTypography type="s-body" className={styles.greyColor1000} data-testid="app-description">
          {application.description}
        </WppTypography>
      )}
      <Flex direction="column" gap={12}>
        <Flex gap={24} align="center">
          <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="application-item-assignee" showName />

          <Calendar
            startDate={application?.startDate}
            endDate={application.endDate}
            data-testid="application-item-dates"
          />
          {application.task?.status && (
            <WppTag
              variant="neutral"
              label={t(`project.tasks.status.${application.task.status}`)!}
              data-testid="app-task-status"
            />
          )}
        </Flex>
      </Flex>
      <WppDivider />

      {selectedVersion && (
        <Flex direction="column" className={styles.versionWrapper}>
          <WppTypography type="s-midi" className={styles.greyColor800}>
            {t('modals.app_details_modal.app_version_title')}
          </WppTypography>
          <WppTypography type="s-strong" className={styles.greyColor1000}>
            {selectedVersion.name} {`(${selectedVersion.versionType.toLowerCase()})`}
          </WppTypography>
        </Flex>
      )}
    </Flex>
  )
}
