import React, { lazy, useContext } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInviteProjectMemberApi } from 'api/projects/mutation/useInviteProjectMemberApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { useExternalMembers } from 'pages/project/hooks/useExternalMembers/useExternalMembers'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectContext } from 'providers/project/ProjectProvider'
import { ProjectRole } from 'types/permissions/permissions'
import { ProjectMember } from 'types/projects/projectMembers'

const Details = lazy(
  () =>
    import(
      'pages/project/components/members/components/inviteMembersModal/memberRecommendations/steps/details/Details'
    ),
)
const Invite = lazy(
  () =>
    import('pages/project/components/members/components/inviteMembersModal/memberRecommendations/steps/invite/Invite'),
)
const JobFamilies = lazy(
  () =>
    import(
      'pages/project/components/members/components/inviteMembersModal/memberRecommendations/steps/jobFamilies/JobFamilies'
    ),
)
const Recommendations = lazy(
  () =>
    import(
      'pages/project/components/members/components/inviteMembersModal/memberRecommendations/steps/recommendations/Recommendations'
    ),
)
const Skills = lazy(
  () =>
    import('pages/project/components/members/components/inviteMembersModal/memberRecommendations/steps/skills/Skills'),
)

export enum SetupStep {
  Details = 1,
  JobFamilies = 2,
  Skills = 3,
  Recommendations = 4,
  Invite = 5,
}

export const SetupStepsOptions = [
  { value: SetupStep.Details, label: 'modals.invite_member.recommendations.steps.details.label' },
  { value: SetupStep.JobFamilies, label: 'modals.invite_member.recommendations.steps.job_families.label' },
  { value: SetupStep.Skills, label: 'modals.invite_member.recommendations.steps.skills.label' },
  {
    value: SetupStep.Recommendations,
    label: 'modals.invite_member.recommendations.steps.recommendations.label',
  },
  { value: SetupStep.Invite, label: 'modals.invite_member.recommendations.steps.invite.label' },
] satisfies {
  value: SetupStep
  label: string
}[]

type ComponentsBySetupStep = {
  [key in SetupStep]: React.ComponentType
}

export interface MembersRole {
  [memberId: string]: ProjectRole
}

interface MembersByRole {
  [role: string]: ProjectMember[]
}

export const componentsBySetupStep: ComponentsBySetupStep = {
  [SetupStep.Details]: Details,
  [SetupStep.Invite]: Invite,
  [SetupStep.JobFamilies]: JobFamilies,
  [SetupStep.Recommendations]: Recommendations,
  [SetupStep.Skills]: Skills,
}

interface Props {
  onClose(): void
  form: UseFormReturn<any>
}

export const useMemberRecommendations = ({ onClose, form }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const {
    project: { id: projectId },
  } = useContext(ProjectContext)
  const { addExternalMembers } = useExternalMembers(projectId)

  const { handleSubmit, watch, setValue } = form

  const [currentStep, members, thirdPartyMembers] = watch(['currentStep', 'members', 'thirdPartyMembers'])

  const { mutateAsync: inviteProjectMembers } = useInviteProjectMemberApi()

  const isDisabledSubmit = currentStep === SetupStep.Recommendations && !members?.length && !thirdPartyMembers?.length

  const selectedMembersCount = members.length + thirdPartyMembers.length

  const actionAreaMessage =
    currentStep === SetupStep.Recommendations && selectedMembersCount
      ? `${selectedMembersCount} member${selectedMembersCount > 1 ? 's' : ''} selected`
      : ''

  const onSubmit = handleSubmit(async values => {
    if (currentStep !== SetupStep.Invite) {
      setValue('currentStep', values.currentStep + 1)
      return
    }

    const normalizedExternalMembers = values.thirdPartyMembers.map((member: ProjectMember) => ({
      ...member,
      projectId,
    }))
    addExternalMembers(normalizedExternalMembers)

    const membersByRole = (values.members as ProjectMember[]).reduce((acc, member) => {
      const role = values.membersRole[member.id] as ProjectRole
      if (!acc[role]) {
        acc[role] = []
      }
      acc[role].push(member)
      return acc
    }, {} as MembersByRole)

    try {
      for (const role of Object.keys(membersByRole) as ProjectRole[]) {
        await inviteProjectMembers({
          projectId,
          role,
          internalMembers: membersByRole[role].map((member: ProjectMember) => ({ email: member.email })),
          externalMembers: [],
        })
      }

      await queryClient.invalidateQueries([ApiQueryKeys.MEMBERS])

      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('modals.invite_member.invite_failed'),
      })
      console.error(e)
    }
  })

  return {
    submitText: t(`modals.invite_member.${currentStep === SetupStep.Invite ? 'btn_invite' : 'btn_next'}`),
    onSubmit,
    isDisabledSubmit,
    actionAreaMessage,
  }
}
