import {
  WppActionButton,
  WppButton,
  WppIconArrow,
  WppSkeleton,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useCreateProjectApi } from 'api/projects/mutation/useCreateProjectApi'
import { useCreateProjectWrikeApi } from 'api/projects/mutation/useCreateProjectWrikeApi'
import { useFetchWorkflowTemplateByIdApi } from 'api/templates/queries/useFetchWorkflowTemplateByIdApi'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ACTION_ANALYTICS, PAGE_ANALYTICS } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useForm } from 'hooks/form/useForm'
import { useTrackAction, useTrackPage } from 'hooks/useAnalytics'
import { useHierarchy } from 'hooks/useHierarchy'
import { useToast } from 'hooks/useToast'
import { ProjectForm } from 'pages/components/projectModal/components/projectForm/ProjectForm'
import { ProjectTemplateForm } from 'pages/components/projectModal/components/projectTemplateForm/ProjectTemplateForm'
import styles from 'pages/components/projectModal/CreateProjectModal.module.scss'
import { normalizeProjectFormDate, useCreateProjectTemplatesCount } from 'pages/components/projectModal/utils'
import { TemplateCard } from 'pages/components/templateCard/TemplateCard'
import { modalValidationScheme } from 'pages/dashboard/components/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { ContextHierarchy, ProcessType, ProjectStatus, ProjectType } from 'types/projects/projects'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { CustomError, is409Error, is422Error } from 'utils/error'
import { routesManager } from 'utils/routesManager'

const SideModalLoadingSkeleton = () => (
  <Flex className={styles.skeletonContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
  </Flex>
)

interface Props extends NiceModalWrappedProps {
  templatePresetId?: string
  showProcessType?: boolean
}

enum CreateStep {
  DETAILS = 'details',
  TEMPLATE_FORM = 'template_form',
}

const defaultValues = {
  name: '',
  type: ProjectType.BLANK,
  description: '',
  templateId: '',
  processType: ProcessType.LINEAR,
  dueDate: [],
}

const CreateProjectModal = ({ isOpen, onClose, onCloseComplete, templatePresetId, showProcessType = true }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const {
    osContext: { navigationTree },
  } = useOs()
  const navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState<CreateStep>(CreateStep.DETAILS)
  const { mutateAsync: createProject } = useCreateProjectApi()
  const { mutateAsync: createProjectWrike } = useCreateProjectWrikeApi()

  useTrackPage(PAGE_ANALYTICS.PAGE_PROJECT_CREATE)
  const { trackAction } = useTrackAction()

  const { hierarchyOrder } = useHierarchy()

  const form = useForm({
    defaultValues: { ...defaultValues, ...hierarchyOrder.reduce((pre, curr) => ({ ...pre, [curr]: '' }), {}) },
    validationSchema: modalValidationScheme(hierarchyOrder),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    getValues,
    setValue,
    trigger,
    watch,
  } = form

  const processType = watch('processType')

  const { templatesCount, isTemplatesCountLoading, counterError } = useCreateProjectTemplatesCount()

  const { data: template, isLoading: isTemplateLoading } = useFetchWorkflowTemplateByIdApi({
    params: { id: getValues().templateId! },
    enabled: !!getValues().templateId,
  })

  useEffect(() => {
    if (!templatePresetId) return
    setValue('templateId', templatePresetId)
  }, [templatePresetId, setValue])

  useEffect(() => {
    if (isTemplateLoading || !template) return
    setValue('processType', template.processType)
  }, [template, isTemplateLoading, setValue])

  // prevent select templates when we have predefined one
  const isTwoStepForm = !templatePresetId

  const onSubmit = handleSubmit(async () => {
    await handleSave()
  })

  const handleNextStep = async () => {
    const isValid = await trigger()
    if (isValid) {
      setCurrentStep(CreateStep.TEMPLATE_FORM)
    }
  }

  const handleSave = useCallback(async () => {
    const { type, description, templateId, processType, dueDate, name, ...rest } = getValues()

    const selectedWorkspace = hierarchyOrder.map(order => rest[order as keyof typeof rest] || '')
    const contextWorkspace = [...selectedWorkspace].reverse().find(el => el !== '') || ''

    const contextHierarchy: ContextHierarchy[] = selectedWorkspace.map((id, index) => {
      const node = navigationTree.mapping[id]
      return {
        title: hierarchyOrder[index],
        name: node?.name || null,
        value: node?.azId || null,
      } as ContextHierarchy
    })

    try {
      const {
        data: { id, name: projectName },
      } = await createProject({
        name: name.trim(),
        type,
        workflowTemplateId: templateId || undefined,
        description: description?.trim() || undefined,
        contextWorkspace: contextWorkspace || undefined,
        status: ProjectStatus.ACTIVE,
        processType: processType,
        contextHierarchy,
        ...normalizeProjectFormDate(dueDate),
      })

      try {
        await createProjectWrike({
          id,
          projectName,
        })
      } catch (e) {}

      Promise.all([
        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FETCHER]),
        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_INFINITE]),
        await tableActions.reload([TableKey.PROJECT_LIST]),
      ])

      trackAction(ACTION_ANALYTICS.ACTION_PROJECT_CREATE, {
        name: name.trim(),
        project_type: type,
        process_type: processType,
        is_created_from_template: `${!!templateId}`,
        context_workspace: contextWorkspace,
      })

      navigate(routesManager.project.root.getURL({ id }))
      onCloseComplete()
    } catch (e) {
      const is422 = is422Error(e)
      const is409 = is409Error(e)

      const CODE = (e as any).response?.data?.detail?.code

      let message = t('modals.create_project.toast_error_common')

      if (is422 && CODE === CustomError.WORKFLOW_TEMPLATE_NOT_FOUND) {
        message = t('modals.create_project.template_not_found')
        setValue('templateId', '')
        queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE])
      }

      if (is409) message = t('modals.create_project.toast_error_duplicate')

      showToast({
        type: 'error',
        message,
      })
    }
  }, [
    hierarchyOrder,
    createProject,
    getValues,
    navigate,
    navigationTree.mapping,
    onCloseComplete,
    setValue,
    showToast,
    t,
    trackAction,
    createProjectWrike,
  ])

  const nextButton = useMemo(
    () =>
      (templatesCount?.fluid !== 0 && processType === ProcessType.FLUID) ||
      (templatesCount?.linear !== 0 && processType === ProcessType.LINEAR),

    [templatesCount, processType],
  )

  const handleBack = () => {
    setCurrentStep(CreateStep.DETAILS)
  }

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen}
          formConfig={{ onSubmit }}
          onWppSideModalClose={onClose}
          onWppSideModalCloseComplete={onCloseComplete}
          size="m"
          data-testid="create-project-modal"
        >
          <WppTypography slot="header" type="2xl-heading">
            {t('modals.create_project.title')}
          </WppTypography>

          {isTemplatesCountLoading ? (
            <div slot="body" className={styles.modalBody}>
              <SideModalLoadingSkeleton />
            </div>
          ) : (
            <>
              <div slot="body" className={styles.modalBody}>
                {currentStep === CreateStep.DETAILS && (
                  <>
                    <ProjectForm showProcessType={showProcessType} templateId={getValues().templateId!} />
                    {templatePresetId && template && (
                      <Flex className={styles.presetContainer} direction="column" gap={12}>
                        <WppTypography slot="header" type="xl-heading">
                          {t('modals.create_project.selected_template')}
                        </WppTypography>
                        <TemplateCard className={styles.templatePreset} template={template} />
                      </Flex>
                    )}
                  </>
                )}
                {currentStep === CreateStep.TEMPLATE_FORM && <ProjectTemplateForm processType={processType} />}
              </div>
              <Flex justify="between" slot="actions">
                <Flex>
                  {currentStep === CreateStep.TEMPLATE_FORM && (
                    <WppActionButton variant="primary" onClick={handleBack} data-testid="project-modal-back-button">
                      <WppIconArrow direction="left" className={styles.backIcon} />
                      {t('common.btn_back')}
                    </WppActionButton>
                  )}
                </Flex>
                <Flex gap={12}>
                  <WppButton variant="secondary" size="m" onClick={onClose}>
                    {t('modals.create_project.btn_cancel')}
                  </WppButton>
                  {nextButton && !counterError && isTwoStepForm && currentStep === CreateStep.DETAILS ? (
                    <WppButton variant="primary" size="m" onClick={handleNextStep}>
                      {t('common.btn_next')}
                    </WppButton>
                  ) : (
                    <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
                      {t('modals.create_project.btn_create')}
                    </WppButton>
                  )}
                </Flex>
              </Flex>

              <Flex justify="between" slot="actions" />
            </>
          )}
        </SideModal>
      </FormProvider>
    </>
  )
}

export const { showModal: showCreateProjectModal } = createNiceModal<Props>(CreateProjectModal, 'create-project-modal')
