import { useContext, useCallback } from 'react'

import { setProjectExternalMembersToLS } from 'pages/project/hooks/useExternalMembers/localStorageUtils'
import { ProjectContext } from 'providers/project/ProjectProvider'
import { Members } from 'types/members/members'

export const useExternalMembers = (projectId: string) => {
  const { externalMembers, setExternalMembers } = useContext(ProjectContext)

  const setMembers = useCallback(
    (updatedMembers: Members[]) => {
      setProjectExternalMembersToLS(projectId, updatedMembers)
      setExternalMembers(updatedMembers)
    },
    [projectId, setExternalMembers],
  )

  const addExternalMembers = useCallback(
    (members: Members[]) => {
      const updatedMembers = [...externalMembers]

      members.forEach(member => {
        const isExists = updatedMembers.some(({ id }) => member.id === id)

        if (!isExists) updatedMembers.push(member)
      })

      setMembers(updatedMembers)
    },
    [externalMembers, setMembers],
  )

  const removeExternalMembers = useCallback(
    (members: Members[]) => {
      const updatedMembers = externalMembers.filter(({ id }) => !members.some(({ id: removeId }) => id === removeId))

      setMembers(updatedMembers)
    },
    [externalMembers, setMembers],
  )

  const updateExternalMember = useCallback(
    (updatedMember: Members) => {
      const updatedMembers = externalMembers.map(member => (member.id === updatedMember.id ? updatedMember : member))

      setMembers(updatedMembers)
    },
    [externalMembers, setMembers],
  )

  return {
    externalMembers,
    addExternalMembers,
    removeExternalMembers,
    updateExternalMember,
  }
}
