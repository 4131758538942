import { UserDetails } from '@wpp-open/core'
import qs from 'qs'

import { facadeApi } from 'api'
import { Paginated } from 'types/common/utils'

export interface Params {
  limit?: number
  offset?: number
  jobFamilies?: string[]
  skills?: string[]
  search?: string
  isAsia?: boolean
}

export type UsersListResponse = Paginated<UserDetails>
const asia = [
  'Brunei Darussalam',
  'Burma',
  'Cambodia',
  'Indonesia',
  'Laos',
  'Malaysia',
  'Philippines',
  'Singapore',
  'Thailand',
  'Vietnam',
]

export const fetchUsersListApi = ({
  offset = 0,
  limit = 20,
  jobFamilies = [],
  skills = [],
  search,
  isAsia = false,
}: Params) =>
  facadeApi.get<UsersListResponse>('/users', {
    params: {
      offset,
      limit,
      'filter[search]': search,
      'filter[active]': 'true',
      ...(isAsia ? { 'filter[country]': asia } : { 'filter[job_families]': jobFamilies, 'filter[skills]': skills }),
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  })
