import { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import styles from 'pages/project/components/members/components/externalMemberForm/ExternalMemberForm.module.scss'
import { useAgencies } from 'pages/project/hooks/useAgencies'

interface Props {
  index: number
}
export const ExternalMemberForm: FC<Props> = ({ index }) => {
  const { t } = useTranslation()

  const { agencies } = useAgencies()

  const agenciesOptions = useMemo(() => {
    return agencies.map(agency => ({ value: agency.id, label: agency.displayName }))
  }, [agencies])

  const { register } = useFormContext()
  // for some reason `onBlur` handler breaks  the `FormInput` validation
  const registerNoEvents = (...args: Parameters<typeof register>) => {
    const { onBlur, onChange, ...rest } = register(...args)
    return rest
  }

  return (
    <div className={styles.externalMembers} data-testid="external-user-form">
      <FormInput
        {...registerNoEvents(`externalMembers.${index}.email`)}
        disabled
        required
        labelConfig={{ text: t('modals.invite_member.field_external_email') }}
        placeholder={t('modals.invite_member.field_external_email')!}
      />

      <FormSelect
        required
        {...registerNoEvents(`externalMembers.${index}.agency`)}
        options={agenciesOptions}
        labelConfig={{ text: t('modals.invite_member.field_external_agency') }}
        placeholder={t('modals.invite_member.field_external_agency_placeholder')!}
      />

      <FormInput
        {...registerNoEvents(`externalMembers.${index}.firstName`)}
        labelConfig={{ text: t('modals.invite_member.field_external_firstname') }}
        required
        placeholder={t('modals.invite_member.field_external_firstname')!}
      />
      <FormInput
        {...registerNoEvents(`externalMembers.${index}.lastName`)}
        required
        labelConfig={{ text: t('modals.invite_member.field_external_lastname') }}
        placeholder={t('modals.invite_member.field_external_lastname')!}
      />
    </div>
  )
}
