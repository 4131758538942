import { AutocompleteOption } from '@platform-ui-kit/components-library'
import { WppTypography } from '@platform-ui-kit/components-library-react'
import React, { useMemo, useRef, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUsersListApi } from 'api/users/queries/useUsersListApi'
import { Flex } from 'components/common/flex/Flex'
import { defaultLocales, FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useToast } from 'hooks/useToast'
import { ExternalMemberForm } from 'pages/project/components/members/components/externalMemberForm/ExternalMemberForm'
import { emailSchema, emailToNames, roleOption } from 'pages/project/components/members/components/utils'
import { ExternalMember } from 'types/projects/projectMembers'
import { fullName } from 'utils/common'

const SearchMembers = () => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const { control, setValue, getValues } = useFormContext()

  const [usersSearch, setUsersSearch] = useState('')

  const { data: users, isLoading: isUsersLoading } = useUsersListApi({
    params: { search: useDebouncedValue(usersSearch.trim(), 300) },
  })

  const usersOptions = useMemo(
    () =>
      users?.data?.map(user => ({ id: user.id, label: fullName(user.firstname, user.lastname), email: user.email })),
    [users.data],
  )

  const { fields, append } = useFieldArray({
    control,
    name: 'externalMembers',
  })

  const membersRef = useRef<HTMLWppAutocompleteElement>(null)

  const addExternalMember = (email: string) => {
    const { success } = emailSchema.safeParse(email)
    if (!success) {
      showToast({
        type: 'error',
        message: t('modals.invite_member.external_user_wrong_email', { email }),
      })
      return
    }

    const externalMember: ExternalMember = {
      id: String(crypto.randomUUID()),
      email,
      ...emailToNames(email),
      agency: '',
    }
    append(externalMember)

    setValue('members', [
      ...getValues('members'),
      {
        ...externalMember,
        firstname: externalMember.firstName,
        lastname: externalMember.lastName,
        jobTitle: '',
      },
    ])

    // small hack, because `WppAutocomplete` does not allow to clean search or close dropdown programmatically
    ;(membersRef.current as any).searchValue = ''
  }

  const syncExternalMembers = (options: AutocompleteOption[]) => {
    setValue(
      'externalMembers',
      getValues('externalMembers').filter((externalMember: any) =>
        options.find(option => option.id === externalMember.id),
      ),
    )
  }

  return (
    <>
      <Flex gap={12}>
        <FormAutocomplete
          ref={membersRef}
          style={{ flexGrow: 1 }}
          required
          name="members"
          multiple
          placeholder={t('modals.invite_member.field_members_placeholder')!}
          labelConfig={{ text: t('modals.invite_member.field_members_label') }}
          loading={isUsersLoading}
          onWppChange={({ detail }) => {
            if (detail.reason === 'removeOption') {
              syncExternalMembers(detail.value as AutocompleteOption[])
            }
          }}
          onWppSearchValueChange={event => setUsersSearch(event.detail)}
          options={usersOptions}
          data-testid="members-autocomplete"
          showCreateNewElement
          onWppCreateNewOption={({ detail: email }) => addExternalMember(email)}
          renderPillContent={option => <>{option.label || option.email}</>}
          locales={{
            ...defaultLocales,
            createNewElement: t('modals.invite_member.external_users_invite', { email: usersSearch }),
          }}
        />
        <FormSelect
          required
          name="role"
          options={roleOption}
          labelConfig={{ text: 'Access' }}
          data-testid="access-select"
        />
      </Flex>
      {!!fields.length && (
        <div>
          <WppTypography type="l-strong">{t('modals.invite_member.external_users_title')}</WppTypography>
          {fields.map(({ id }, index) => (
            <ExternalMemberForm key={id} index={index} />
          ))}
        </div>
      )}
    </>
  )
}

export default SearchMembers
