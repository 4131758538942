import {
  WppActionButton,
  WppButton,
  WppCard,
  WppIconEdit,
  WppIconMore,
  WppIconPlus,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
  WppPill,
  WppTag,
  WppTypography,
  WppTooltip,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppIconClock,
} from '@platform-ui-kit/components-library-react'
import { SelectionChangedEvent } from 'ag-grid-community/dist/lib/events'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, Table } from 'components/common/table'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { showInviteMembersModal } from 'pages/project/components/members/components/inviteMembersModal/InviteMembersModal'
import { showMemberModal } from 'pages/project/components/members/components/memberModal/MemberModal'
import { showRemoveMembersModal } from 'pages/project/components/members/components/removeMembersModal/RemoveMembersModal'
import styles from 'pages/project/components/members/Members.module.scss'
import { MemberSourceType } from 'pages/project/components/members/utils'
import { useExternalMembers } from 'pages/project/hooks/useExternalMembers/useExternalMembers'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { Members } from 'types/members/members'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProjectStatus } from 'types/projects/projects'
import { capitalizeFirstLetter, join } from 'utils/common'

export const MembersTab = () => {
  const gridRef = useRef<AgGridReact<Members>>(null)
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(MemberSourceType.Internal)
  const isInternalTab = activeTab === MemberSourceType.Internal
  const { project, members, isMembersLoading, isInactive } = useProject()
  const [selectedMembers, setSelectedMembers] = useState<Members[]>([])
  const { externalMembers } = useExternalMembers(project.id)
  const { isResponsible } = useHasProjectRole()

  const { isPermitted } = useIsPermitted()
  const { hasRole } = useHasProjectRole()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const ownersSelected = useMemo(
    () => selectedMembers.filter(member => member.role === ProjectRole.OWNER),
    [selectedMembers],
  )
  const allOwners = useMemo(() => members.filter(member => member.role === ProjectRole.OWNER), [members])
  const isSelectedResponsible = useMemo(
    () => selectedMembers.some(member => isResponsible(member.email)),
    [isResponsible, selectedMembers],
  )

  const isProjectDone = [ProjectStatus.COMPLETED, ProjectStatus.ARCHIVED].includes(project.status)

  useEffect(() => {
    if (gridRef.current) setSelectedMembers(gridRef.current?.api?.getSelectedRows() || [])
  }, [members, externalMembers])

  useEffect(() => {
    if (!isInternalTab && !externalMembers.length) {
      setActiveTab(MemberSourceType.Internal)
      setSelectedMembers([])
    }
  }, [externalMembers, isInternalTab])

  const openMemberModal = useCallback(
    ({
      member: selectedMember,
      allOwnersSelected,
      memberType,
    }: {
      member: Members
      allOwnersSelected: boolean
      memberType?: MemberSourceType
    }) => {
      showMemberModal({ member: selectedMember, project, allOwnersSelected, memberType })
    },
    [project],
  )

  const openDeleteMemberModal = useCallback(
    (member: Members[], memberType?: MemberSourceType) => {
      showRemoveMembersModal({
        membersToDelete: member,
        projectId: project.id,
        memberType,
      })
    },
    [project.id],
  )

  const removeTolTip = useMemo(() => {
    if (isProjectDone && isSelectedResponsible) {
      return t('project.members.cant_remove_responsible')!
    }

    if (allOwners.length === 1) {
      return t('project.members.cant_remove_owner')!
    }

    return t('project.members.cant_remove_all_owners')!
  }, [allOwners.length, isProjectDone, isSelectedResponsible, t])

  const columnDefs = useMemo<ColDef<Members>[]>(
    () => [
      {
        colId: 'name',
        flex: 1,
        headerCheckboxSelection: isOwnerOrGlobalManage,
        checkboxSelection: isOwnerOrGlobalManage,
        headerName: 'Name',
        cellRenderer: ({ data }) => {
          const { firstname, lastname, email, avatarUrl } = data!
          const userName = join([firstname, lastname], ' ')

          return (
            <Flex gap={16} align="center">
              <Avatar size="s" name={userName || email} src={avatarUrl ?? ''} />
              <Flex direction="column" className={styles.overflow}>
                <WppTypography type="s-body" data-testid="table-username">
                  {userName}
                </WppTypography>
                <WppTypography type="xs-body" data-testid="table-email">
                  {email}
                </WppTypography>
              </Flex>
            </Flex>
          )
        },
      },
      {
        colId: 'agency',
        flex: 1,
        headerName: 'OpCo',
        cellRenderer: ({ data }) => <WppTypography type="s-body">{data?.agency}</WppTypography>,
      },
      {
        colId: 'jobTitle',
        flex: 1,
        headerName: 'Title',
        cellRenderer: ({ data }) => <WppTypography type="s-body">{data?.jobTitle}</WppTypography>,
      },
      {
        colId: 'access',
        headerName: 'Access',
        flex: 1,
        cellRenderer: ({ data }) => {
          return (
            <WppTag
              label={capitalizeFirstLetter(data?.role?.toLowerCase())}
              className={styles[data?.role || ProjectRole.VIEWER]}
              variant="neutral"
            />
          )
        },
      },
      isOwnerOrGlobalManage
        ? {
            width: 60,
            colId: 'actions',
            cellRenderer: ({ data }) => (
              <WppMenuContext
                className={styles.rowContextMenu}
                dropdownConfig={{
                  appendTo: () => document.body,
                  placement: 'bottom-end',
                }}
              >
                <WppActionButton slot="trigger-element">
                  <WppIconMore direction="horizontal" slot="icon-start" />
                </WppActionButton>
                {(allOwners.length === 1 && data?.role === ProjectRole.OWNER) ||
                (isProjectDone && isResponsible(data?.email)) ? (
                  <WppTooltip config={{ placement: 'left' }} text={removeTolTip}>
                    <WppListItem data-testid="delete-group-context-action" disabled={true}>
                      <WppIconTrash slot="left" />
                      <span slot="label"> {t('common.btn_remove')}</span>
                    </WppListItem>
                  </WppTooltip>
                ) : (
                  <Flex direction="column" gap={4}>
                    <WppListItem
                      onWppChangeListItem={() =>
                        openMemberModal({
                          member: data!,
                          allOwnersSelected: allOwners.length === 1 && data?.role === ProjectRole.OWNER,
                        })
                      }
                      data-testid="edit-group-action"
                    >
                      <WppIconEdit slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('common.btn_edit')}
                      </WppTypography>
                    </WppListItem>
                    {(allOwners.length === 1 && data?.role === ProjectRole.OWNER) ||
                    (isProjectDone && isResponsible(data?.email)) ? (
                      <WppTooltip config={{ placement: 'left' }} text={removeTolTip}>
                        <WppListItem data-testid="delete-group-context-action" disabled={true}>
                          <WppIconTrash slot="left" />
                          <span slot="label"> {t('common.btn_remove')}</span>
                        </WppListItem>
                      </WppTooltip>
                    ) : (
                      <WppListItem
                        onWppChangeListItem={() => openDeleteMemberModal([data!])}
                        data-testid="delete-group-context-action"
                      >
                        <WppIconTrash slot="left" />
                        <WppTypography slot="label" type="s-body">
                          {t('common.btn_remove')}
                        </WppTypography>
                      </WppListItem>
                    )}
                  </Flex>
                )}
              </WppMenuContext>
            ),
            maxWidth: 60,
          }
        : {},
    ],
    [
      isOwnerOrGlobalManage,
      t,
      allOwners.length,
      isProjectDone,
      isResponsible,
      removeTolTip,
      openMemberModal,
      openDeleteMemberModal,
    ],
  )

  const columnExternalDefs = useMemo<ColDef<Members>[]>(
    () => [
      {
        colId: 'name',
        flex: 1,
        headerCheckboxSelection: isOwnerOrGlobalManage,
        checkboxSelection: isOwnerOrGlobalManage,
        headerName: 'Name',
        cellRenderer: ({ data }) => {
          const { firstname, lastname, email, avatarUrl } = data!
          const userName = join([firstname, lastname], ' ')

          return (
            <Flex gap={16} align="center">
              <Avatar size="s" name={userName || email} src={avatarUrl ?? ''} />
              <Flex direction="column" className={styles.overflow}>
                <WppTypography type="s-body" data-testid="table-username">
                  {userName}
                </WppTypography>
                <WppTypography type="xs-body" data-testid="table-email">
                  {email}
                </WppTypography>
              </Flex>
            </Flex>
          )
        },
      },
      {
        colId: 'jobTitle',
        flex: 1,
        headerName: 'Title',
        cellRenderer: ({ data }) => <WppTypography type="s-body">{data?.jobTitle}</WppTypography>,
      },
      {
        colId: 'status',
        headerName: 'Status',
        flex: 1,
        cellRenderer: () => {
          return (
            <WppTag label="Contacted" variant="neutral">
              <WppIconClock slot="icon-start" />
            </WppTag>
          )
        },
      },
      isOwnerOrGlobalManage
        ? {
            width: 60,
            colId: 'actions',
            cellRenderer: ({ data }) => (
              <WppMenuContext
                className={styles.rowContextMenu}
                dropdownConfig={{
                  appendTo: () => document.body,
                  placement: 'bottom-end',
                }}
              >
                <WppActionButton slot="trigger-element">
                  <WppIconMore direction="horizontal" />
                </WppActionButton>
                <WppListItem
                  onWppChangeListItem={() =>
                    openMemberModal({
                      member: data!,
                      allOwnersSelected: allOwners.length === 1 && data?.role === ProjectRole.OWNER,
                      memberType: MemberSourceType.External,
                    })
                  }
                  data-testid="edit-group-action"
                >
                  <WppIconEdit slot="left" />
                  <WppTypography slot="label" type="s-body">
                    {t('common.btn_edit')}
                  </WppTypography>
                </WppListItem>
                {(allOwners.length === 1 && data?.role === ProjectRole.OWNER) ||
                (isInactive && isResponsible(data?.email)) ? (
                  <WppTooltip config={{ placement: 'left' }} text={removeTolTip}>
                    <WppListItem data-testid="delete-group-context-action" disabled={true}>
                      <WppIconTrash slot="left" />
                      <span slot="label"> {t('common.btn_remove')}</span>
                    </WppListItem>
                  </WppTooltip>
                ) : (
                  <WppListItem
                    onWppChangeListItem={() => openDeleteMemberModal([data!], MemberSourceType.External)}
                    data-testid="delete-group-context-action"
                  >
                    <WppIconTrash slot="left" />
                    <WppTypography slot="label" type="s-body">
                      {t('common.btn_remove')}
                    </WppTypography>
                  </WppListItem>
                )}
              </WppMenuContext>
            ),
            maxWidth: 60,
          }
        : {},
    ],
    [
      isOwnerOrGlobalManage,
      t,
      allOwners.length,
      isInactive,
      isResponsible,
      removeTolTip,
      openMemberModal,
      openDeleteMemberModal,
    ],
  )

  const handleSelectionChanged = ({ api }: SelectionChangedEvent) => setSelectedMembers(api.getSelectedRows())

  return (
    <>
      <WppCard className={styles.container}>
        <Flex direction="column" gap={29} style={{ height: '100%', width: '100%' }}>
          <Flex justify="between">
            <Flex justify="start">
              {!!externalMembers.length && (
                <WppSegmentedControl
                  size="m"
                  value={activeTab}
                  onWppChange={e => {
                    setActiveTab(e.detail.value! as MemberSourceType)
                  }}
                >
                  <WppSegmentedControlItem value={MemberSourceType.Internal}>
                    {t('modals.invite_member.member_source_type.select_option.internal')}
                  </WppSegmentedControlItem>
                  <WppSegmentedControlItem value={MemberSourceType.External}>
                    {t('modals.invite_member.member_source_type.select_option.external')}
                  </WppSegmentedControlItem>
                </WppSegmentedControl>
              )}
            </Flex>
            <Flex justify="end">
              {isOwnerOrGlobalManage && (
                <Flex gap={20} align="center">
                  {!!selectedMembers.length ? (
                    <>
                      <WppPill
                        removable={true}
                        type="display"
                        label={`${selectedMembers.length} selected`}
                        name="chip"
                        onWppClose={() => gridRef.current?.api.deselectAll()}
                      />

                      {ownersSelected.length === allOwners.length || (isSelectedResponsible && isProjectDone) ? (
                        <WppTooltip text={removeTolTip}>
                          <WppActionButton variant="destructive" data-testid="delete-group-card-action" disabled={true}>
                            <WppIconTrash slot="icon-start" />
                            {t('common.btn_remove')}
                          </WppActionButton>
                        </WppTooltip>
                      ) : (
                        <WppActionButton
                          variant="destructive"
                          data-testid="delete-group-card-action"
                          onClick={() =>
                            openDeleteMemberModal(
                              selectedMembers,
                              isInternalTab ? MemberSourceType.Internal : MemberSourceType.External,
                            )
                          }
                        >
                          <WppIconTrash slot="icon-start" />
                          {t('common.btn_remove')}
                        </WppActionButton>
                      )}
                    </>
                  ) : (
                    <WppButton size="s" onClick={() => showInviteMembersModal()} disabled={isMembersLoading}>
                      <WppIconPlus slot="icon-start" />
                      {t('project.members.invite_members')}
                    </WppButton>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
          <Table
            ref={gridRef}
            rowData={isInternalTab ? members : externalMembers}
            cacheBlockSize={50}
            rowHeight={60}
            columnDefs={isInternalTab ? columnDefs : columnExternalDefs}
            rowSelection="multiple"
            suppressPaginationPanel
            suppressRowClickSelection
            onSelectionChanged={handleSelectionChanged}
          />
        </Flex>
      </WppCard>
    </>
  )
}
