import { useContext } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInviteProjectMemberApi } from 'api/projects/mutation/useInviteProjectMemberApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectContext } from 'providers/project/ProjectProvider'
import { ExternalMember, ProjectMember } from 'types/projects/projectMembers'

interface Props {
  onClose(): void
  form: UseFormReturn<any>
}

export const useSearchMembers = ({ onClose, form }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const {
    project: { id: projectId },
  } = useContext(ProjectContext)

  const { mutateAsync: inviteProjectMembers } = useInviteProjectMemberApi()

  const {
    handleSubmit,
    formState: { isValid },
    trigger,
  } = form

  const onSubmit = handleSubmit(async values => {
    if (!isValid) {
      await trigger()
      return
    }

    const { members, role, externalMembers } = values
    const internalMembers = !externalMembers.length
      ? members
      : members?.filter(
          (member: ProjectMember) =>
            !externalMembers.find((externalMember: ExternalMember) => externalMember.id === member.id),
        )

    try {
      await inviteProjectMembers({
        projectId,
        role,
        internalMembers: internalMembers.map((member: ProjectMember) => ({ email: member?.email })),
        externalMembers,
      })

      await queryClient.invalidateQueries([ApiQueryKeys.MEMBERS])
      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('modals.invite_member.invite_failed'),
      })
      console.error(e)
    }
  })

  return { submitText: t('modals.invite_member.btn_invite'), onSubmit }
}
