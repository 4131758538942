import { pitchRNDApi } from 'api'
import { MayBeNull } from 'types/common/utils'
import { HighlightApplication } from 'types/projects/highlightPreferences'

export interface CreateHighlightPreference {
  id: string
  name: string
  phaseId?: MayBeNull<string>
  applications: HighlightApplication[]
}

export const createHighlightPreference = (body: CreateHighlightPreference) =>
  pitchRNDApi.post('/projects/highlighted_preferences', body)
