import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useProjectWidgetViewConfiguration } from 'api/projects/queries/useProjectWidgetViewConfiguration'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { useProject } from 'hooks/useProject'
import { MembersCard } from 'pages/project/components/projectDashboard/coponents/membersCard/MembersCard'
import { PinnedFilesCard } from 'pages/project/components/projectDashboard/coponents/pinnedFilesCard/PinnedFilesCard'
import { ProjectInfo } from 'pages/project/components/projectDashboard/coponents/projectInfo/ProjectInfo'
import { TasksStatusBoard } from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/TasksStatusBoard'
import { WorkflowWidget } from 'pages/project/components/projectDashboard/coponents/workflowWidget/WorkflowWidget'
import styles from 'pages/project/components/projectDashboard/ProjectDashboard.module.scss'
import { MayBeNull } from 'types/common/utils'
import { ProcessType } from 'types/projects/projects'

const dashboardConfig = {
  showTasksProgress: true,
  showProjectDetails: true,
  showMembers: true,
  showWorkflow: true,
} as const

interface DashboardSectionProps {
  shouldRenderComponent: boolean
  componentToRender: ReactNode
  fallbackContent?: MayBeNull<ReactNode>
}

const WORKFLOW_WIDGET_ENABLED = false

const DashboardSection = ({ shouldRenderComponent, componentToRender, fallbackContent }: DashboardSectionProps) => {
  return shouldRenderComponent ? componentToRender : fallbackContent
}

export const ProjectDashboard = () => {
  const { project } = useProject()
  const { t } = useTranslation()

  const { data: widgetConfiguration } = useProjectWidgetViewConfiguration({
    enabled: !!project.id,
  })

  const checkIfAllWidgetsAreDisabled = useMemo(() => {
    if (!widgetConfiguration) return
    const { showWorkflow, showTasksProgress, showProjectDetails, showMembers } = widgetConfiguration
    return !showWorkflow && !showTasksProgress && !showProjectDetails && !showMembers
  }, [widgetConfiguration])

  return (
    <>
      {checkIfAllWidgetsAreDisabled ? (
        <EmptyState
          title={t('project.about.no_results_title')}
          description={t('project.about.no_results_description')}
          testToken="results"
        />
      ) : (
        <Flex direction="column" gap={28} className={styles.cont}>
          {WORKFLOW_WIDGET_ENABLED && (
            <DashboardSection
              shouldRenderComponent={
                (widgetConfiguration?.showWorkflow ?? dashboardConfig.showWorkflow) &&
                project.processType === ProcessType.LINEAR
              }
              componentToRender={<WorkflowWidget />}
            />
          )}
          <div className={styles.containerGrid}>
            <DashboardSection
              shouldRenderComponent={widgetConfiguration?.showTasksProgress ?? dashboardConfig.showTasksProgress}
              componentToRender={<TasksStatusBoard />}
            />
            <Flex direction="column" gap={28}>
              <DashboardSection
                shouldRenderComponent={widgetConfiguration?.showProjectDetails ?? dashboardConfig.showProjectDetails}
                componentToRender={<ProjectInfo />}
              />

              <div className={styles.childContainerGrid}>
                <DashboardSection
                  shouldRenderComponent={widgetConfiguration?.showMembers ?? dashboardConfig.showMembers}
                  componentToRender={<MembersCard />}
                />
                <DashboardSection
                  shouldRenderComponent={!!widgetConfiguration?.showFiles}
                  componentToRender={<PinnedFilesCard />}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      )}
    </>
  )
}
