import { WppStepper, WppStep } from '@platform-ui-kit/components-library-react'
import React, { Suspense } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/members/components/inviteMembersModal/InviteMembersModal.module.scss'
import {
  SetupStep,
  SetupStepsOptions,
  componentsBySetupStep,
} from 'pages/project/components/members/components/inviteMembersModal/memberRecommendations/utils'

const MemberRecommendations = () => {
  const { t } = useTranslation()

  const { getValues } = useFormContext()

  const currentStep = getValues('currentStep') as SetupStep

  const SetupStepComponent = componentsBySetupStep[currentStep]

  return (
    <Flex direction="column">
      <Flex direction="column">
        <WppStepper activeStep={currentStep} orientation="horizontal" className={styles.stepper}>
          {SetupStepsOptions.map(opt => (
            <WppStep key={opt.value}>
              <p slot="label" className={styles.stepLabel}>
                {t(opt.label)}
              </p>
            </WppStep>
          ))}
        </WppStepper>
      </Flex>
      <Flex direction="column">
        <Suspense>
          <SetupStepComponent />
        </Suspense>
      </Flex>
    </Flex>
  )
}

export default MemberRecommendations
