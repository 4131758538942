import { Members } from 'types/members/members'

export type ExternalMembers = Record<string, Members[]>

const EXTERNAL_MEMBERS_LS_KEY = 'EXTERNAL_MEMBERS'

const getExternalMembersFromLS = (): ExternalMembers =>
  JSON.parse(localStorage.getItem(EXTERNAL_MEMBERS_LS_KEY) || '{}')

const setExternalMembersToLS = (externalMembers: ExternalMembers) =>
  localStorage.setItem(EXTERNAL_MEMBERS_LS_KEY, JSON.stringify(externalMembers))

export const getProjectExternalMembersFromLS = (projectId: string): Members[] =>
  getExternalMembersFromLS()[projectId] || []

export const setProjectExternalMembersToLS = (projectId: string, members: Members[]) => {
  const externalMembers = getExternalMembersFromLS()
  externalMembers[projectId] = members

  setExternalMembersToLS(externalMembers)
}
