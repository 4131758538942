export enum MemberSourceType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
}

export const MemberSourceTypeOptions = [
  { value: MemberSourceType.Internal, label: 'modals.invite_member.member_source_type.select_option.internal' },
  { value: MemberSourceType.External, label: 'modals.invite_member.member_source_type.select_option.external' },
] satisfies {
  value: MemberSourceType
  label: string
}[]
