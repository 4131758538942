import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/workspaceWarning/WorkspaceWarning.module.scss'
import { ContextHierarchy } from 'types/projects/projects'

export const WorkspaceWarning = ({ contextHierarchy }: { contextHierarchy: ContextHierarchy[] }) => {
  return (
    !!contextHierarchy?.length && (
      <Flex gap={26}>
        {contextHierarchy.map(el => (
          <Flex direction="column">
            <WppTypography className={styles.title} type="s-midi">
              {el.title}
            </WppTypography>
            <WppTypography className={styles.fontColor} type="m-strong">
              {el.name || '-'}
            </WppTypography>
          </Flex>
        ))}
      </Flex>
    )
  )
}
