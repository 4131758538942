import { ProjectMember } from 'types/projects/projectMembers'

export enum InviteMembersType {
  SearchMembers = 'SEARCH_MEMBERS',
  MemberRecommendations = 'MEMBER_RECOMMENDATIONS',
}

export const InviteMembersOptions = [
  { value: InviteMembersType.SearchMembers, label: 'modals.invite_member.select_option.search_members' },
  {
    value: InviteMembersType.MemberRecommendations,
    label: 'modals.invite_member.select_option.member_recommendations',
  },
] satisfies {
  value: InviteMembersType
  label: string
}[]

const linkedInSourceType = 'Linkedin'

const openmindDomain = '@openmindworld.com'

export const checkLinkedInSource = (user: ProjectMember) => user.sourceType === linkedInSourceType

export const checkOpenmindSource = (user: ProjectMember) => user.email.includes(openmindDomain)
